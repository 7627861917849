
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_1wepg_7vonf_141",
  "description": "awsui_description_1wepg_7vonf_142",
  "label": "awsui_label_1wepg_7vonf_143",
  "outline": "awsui_outline_1wepg_7vonf_151",
  "show-outline": "awsui_show-outline_1wepg_7vonf_154",
  "native-input": "awsui_native-input_1wepg_7vonf_158",
  "wrapper": "awsui_wrapper_1wepg_7vonf_162",
  "label-wrapper": "awsui_label-wrapper_1wepg_7vonf_168",
  "empty-content": "awsui_empty-content_1wepg_7vonf_179",
  "description-bottom-padding": "awsui_description-bottom-padding_1wepg_7vonf_189",
  "label-disabled": "awsui_label-disabled_1wepg_7vonf_197",
  "description-disabled": "awsui_description-disabled_1wepg_7vonf_198",
  "control": "awsui_control_1wepg_7vonf_202"
};
  