
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_m15k0_281",
  "arrow-outer": "awsui_arrow-outer_xjuzf_m15k0_285",
  "arrow-inner": "awsui_arrow-inner_xjuzf_m15k0_285",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_m15k0_327",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_m15k0_327",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_m15k0_330",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_m15k0_330",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_m15k0_333",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_m15k0_333",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_m15k0_333",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_m15k0_333",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_m15k0_336",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_m15k0_336",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_m15k0_336",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_m15k0_336",
  "body": "awsui_body_xjuzf_m15k0_480",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_m15k0_519",
  "has-dismiss": "awsui_has-dismiss_xjuzf_m15k0_523",
  "dismiss": "awsui_dismiss_xjuzf_m15k0_528",
  "dismiss-control": "awsui_dismiss-control_xjuzf_m15k0_536",
  "header-row": "awsui_header-row_xjuzf_m15k0_540",
  "header": "awsui_header_xjuzf_m15k0_540",
  "content": "awsui_content_xjuzf_m15k0_565",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_m15k0_573",
  "container": "awsui_container_xjuzf_m15k0_717",
  "container-body": "awsui_container-body_xjuzf_m15k0_725",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_m15k0_768",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_m15k0_773",
  "fixed-width": "awsui_fixed-width_xjuzf_m15k0_776",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_m15k0_780",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_m15k0_787",
  "container-arrow": "awsui_container-arrow_xjuzf_m15k0_799",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_m15k0_803",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_m15k0_803",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_m15k0_820",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_m15k0_820",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_m15k0_837",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_m15k0_837",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_m15k0_837",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_m15k0_837",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_m15k0_853",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_m15k0_857",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_m15k0_861",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_m15k0_1",
  "refresh": "awsui_refresh_xjuzf_m15k0_1027",
  "root": "awsui_root_xjuzf_m15k0_1049",
  "root-filtering-token": "awsui_root-filtering-token_xjuzf_m15k0_1085",
  "trigger": "awsui_trigger_xjuzf_m15k0_1089",
  "overflow-ellipsis": "awsui_overflow-ellipsis_xjuzf_m15k0_1096",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_m15k0_1104",
  "trigger-type-filtering-token": "awsui_trigger-type-filtering-token_xjuzf_m15k0_1140",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_m15k0_1144"
};
  