
    import './styles.scoped.css';
    export default {
  "header": "awsui_header_16mm3_1axeh_141",
  "disabled": "awsui_disabled_16mm3_1axeh_156",
  "expandable-header": "awsui_expandable-header_16mm3_1axeh_160",
  "rolled-down": "awsui_rolled-down_16mm3_1axeh_171",
  "highlighted": "awsui_highlighted_16mm3_1axeh_174",
  "is-focused": "awsui_is-focused_16mm3_1axeh_192",
  "category": "awsui_category_16mm3_1axeh_197",
  "expandable": "awsui_expandable_16mm3_1axeh_160",
  "variant-navigation": "awsui_variant-navigation_16mm3_1axeh_212",
  "expand-icon": "awsui_expand-icon_16mm3_1axeh_219",
  "expand-icon-up": "awsui_expand-icon-up_16mm3_1axeh_236",
  "expand-icon-right": "awsui_expand-icon-right_16mm3_1axeh_239",
  "items-list-container": "awsui_items-list-container_16mm3_1axeh_247",
  "in-dropdown": "awsui_in-dropdown_16mm3_1axeh_256"
};
  