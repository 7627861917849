
    import './styles.scoped.css';
    export default {
  "icon": "awsui_icon_h11ix_229dc_185",
  "icon-flex-height": "awsui_icon-flex-height_h11ix_229dc_193",
  "size-small": "awsui_size-small_h11ix_229dc_221",
  "size-small-mapped-height": "awsui_size-small-mapped-height_h11ix_229dc_225",
  "size-normal": "awsui_size-normal_h11ix_229dc_240",
  "size-normal-mapped-height": "awsui_size-normal-mapped-height_h11ix_229dc_244",
  "size-medium": "awsui_size-medium_h11ix_229dc_259",
  "size-medium-mapped-height": "awsui_size-medium-mapped-height_h11ix_229dc_263",
  "size-big": "awsui_size-big_h11ix_229dc_278",
  "size-big-mapped-height": "awsui_size-big-mapped-height_h11ix_229dc_282",
  "size-large": "awsui_size-large_h11ix_229dc_297",
  "size-large-mapped-height": "awsui_size-large-mapped-height_h11ix_229dc_301",
  "variant-normal": "awsui_variant-normal_h11ix_229dc_316",
  "variant-disabled": "awsui_variant-disabled_h11ix_229dc_319",
  "variant-inverted": "awsui_variant-inverted_h11ix_229dc_322",
  "variant-subtle": "awsui_variant-subtle_h11ix_229dc_325",
  "variant-warning": "awsui_variant-warning_h11ix_229dc_328",
  "variant-error": "awsui_variant-error_h11ix_229dc_331",
  "variant-success": "awsui_variant-success_h11ix_229dc_334",
  "variant-link": "awsui_variant-link_h11ix_229dc_337",
  "name-angle-left-double": "awsui_name-angle-left-double_h11ix_229dc_340",
  "name-angle-left": "awsui_name-angle-left_h11ix_229dc_340",
  "name-angle-right-double": "awsui_name-angle-right-double_h11ix_229dc_342",
  "name-angle-right": "awsui_name-angle-right_h11ix_229dc_342",
  "name-arrow-left": "awsui_name-arrow-left_h11ix_229dc_344",
  "name-caret-left-filled": "awsui_name-caret-left-filled_h11ix_229dc_345",
  "name-caret-right-filled": "awsui_name-caret-right-filled_h11ix_229dc_346",
  "name-audio-full": "awsui_name-audio-full_h11ix_229dc_347",
  "name-audio-half": "awsui_name-audio-half_h11ix_229dc_348",
  "name-audio-off": "awsui_name-audio-off_h11ix_229dc_349",
  "name-external": "awsui_name-external_h11ix_229dc_350",
  "name-redo": "awsui_name-redo_h11ix_229dc_351",
  "name-resize-area": "awsui_name-resize-area_h11ix_229dc_352",
  "name-send": "awsui_name-send_h11ix_229dc_353",
  "name-shrink": "awsui_name-shrink_h11ix_229dc_354",
  "name-undo": "awsui_name-undo_h11ix_229dc_355",
  "name-view-vertical": "awsui_name-view-vertical_h11ix_229dc_356",
  "badge": "awsui_badge_h11ix_229dc_379"
};
  